<template>
  <div class="contract-1">
    <custom-table
      :loadData="loadData"
      :remove="remove"
      :drawers="drawers"
      :edit="edit"
      :pageInfo="pageInfo"
      :filters="filters"
      :tableColumns="tableColumns"
    />
  </div>
</template>

<script>
import * as moment from "moment";
import { list, add, remove, update } from "@/api/office.js";
import { customerList } from "@/api/customer";
const type = 5;
export default {
  data() {
    return {
      filters: [
        {
          name: "公关费名称",
          value: "",
          property: "projectName",
          defaultValue() {
            return "";
          },
          type: "input",
          clearable: true,
        },
      ],
      drawers: [
        {
          name: "公关费名称",
          value: "",
          property: "name",
          defaultValue() {
            return "";
          },
          type: "input",
          require: true,
          clearable: true,
        },
         {
          name: "客户名称",
          property: "customerName",
          value: null,
          type: "select",
          require: true,
          options:[],
          defaultValue() {
            return null;
          },
          clearable: true,
        },
        {
          name: "公关费日期",
          property: "date",
          value: null,
          type: "date",
          require: true,
          defaultValue() {
            return null;
          },
          clearable: true,
        },
        {
          name: "公关费金额",
          property: "moneyAmount",
          value: 0,
          type: "number",
          min: 0,
          require: true,
          defaultValue() {
            return 0;
          },
          formatCommit(e) {
            return e.toFixed(2);
          },
          clearable: true,
        },
        {
          name: "备注",
          property: "bz",
          value: 0,
          type: "textarea",
          require: false,
          defaultValue() {
            return "";
          },
          clearable: true,
        },
      ],
      tableColumns: [
        {
          name: "公关费名称",
          props: "name",
        },
            {
          name: "客户名称",
          props: "customerName",
        },
        {
          name: "公关费金额",
          props: "moneyAmount",
          isFormat: true,
          format: (price) => {
            return "￥" + price;
          },
        },
        {
          name: "公关费日期",
          props: "date",
          isFormat: true,
          format: (date) => {
            return moment(new Date(date)).format("YYYY-MM-DD");
          },
          formatCommit: (date) => {
            return moment(new Date(date)).format("YYYY-MM-DD");
          },
        },
        {
          name: "备注",
          props: "bz",
          // isFormat: true,
        },
        {
          name: "操作",
          isEdit: true,
          edits: ["remove", "edit"],
        },
      ],
      pageInfo: {
        pageSize: 10,
        pageNumber: 1,
        total: 1000,
      },
    };
  },

  methods: {
    async customerList(){
         let data2 = await customerList({
        pageSize: 10000,
        pageNumber: 1,
        type: 0,
      });

      let list2 = data2.data.list;
      list2.map((e) => {
        e.type = e.name;
      });

      this.drawers[1].options=list2
    },
    async loadData(data) {
      // getTotalMoney().then(({data})=>{
      //     this.totalData=data
      // })
      let lists = [];
      let total = 0;
      try {
        let res = await list({ ...data, type });
        lists = res.data.basicPageVo.list;
        total = res.data.basicPageVo.total;
      } catch (error) {}
      return {
        list: lists,
        total,
      };
    },
    async remove(row) {
      await remove({
        id: row.id,
        type,
      });
      $office.loadData();
    },
    async edit(data) {
      // delete data['id']
      let httpMethod = data.drawerType == "edit" ? update : add;
      delete data["drawerType"];
      $office.loadData();
      await httpMethod({ office2Dto: { ...data, type, url: "" } });

      $office.loadData();
    },
  },
  created(){
    this.customerList()
  }
};
</script>

import axios from "@/lib/axios";

export const list = (params) => {
    return axios.request({
        url: "office/getOffice",
        params
    })
}

export const add=(data)=>{
    return axios.request({
        url:"office/addOffice",
        data,
        method:'post'
    })
}

export const remove=({
    id,type
})=>{
    return axios.request({
        url:"office/deleteOffice",
        params:{
            id,type
        }
    })
}

export const update=(data)=>{
    return axios.request({
        url:"office/updateOffice",
        data,
        method:"post"
    })
}


export const updateAffix=(data)=>{
    return axios.request({
        url:"office/updateAffix",
        data,
        method:"post"
    })
}

export const addAffix=(data)=>{
    return axios.request({
        url:"office/addAffix",
        data,
        method:"post"
    })
}

export const getMoneyMessage=()=>{
    return axios.request({
        url:"office/getMoneyMessage",
    })
}
<!--
 * @Author: yym 12332132@qq.com
 * @Date: 2023-03-09 10:38:19
 * @LastEditors: yym 12332132@qq.com
 * @LastEditTime: 2023-03-13 14:14:30
 * @FilePath: \wealth\src\views\index\views\offical\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="contract-section">
    <statistical-all :statistical="statistical"></statistical-all>
    <el-tabs v-model="activeName">
      <el-tab-pane label="用品采购" name="first">
        <page-1></page-1>
      </el-tab-pane>
      <el-tab-pane label="房租费" name="second">
        <page-2></page-2>
      </el-tab-pane>
      <el-tab-pane label="物业费" name="third"> <page-3></page-3> </el-tab-pane>
      <el-tab-pane label="水电费" name="fourth">
        <page-4></page-4>
      </el-tab-pane>

      <el-tab-pane label="手续费" name="fifth"> <page-5></page-5> </el-tab-pane>
      <el-tab-pane label="公关费" name="sixth"> <page-6></page-6> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { list, add, remove, update, getTotalMoney } from "@/api/fix.js";
import { getMoneyMessage } from "../../../../api/office";
const type = 0;
import page1 from "./components/1.vue";
import page2 from "./components/2.vue";
import page3 from "./components/3.vue";
import page4 from "./components/4.vue";
import page5 from "./components/5.vue";
import page6 from "./components/6.vue";
export default {
  data() {
    return {
      activeName: "first",
      totalData: {},
    };
  },
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
  },
  computed: {
    statistical() {
      let data = [
        {
          name: "用品采购",
          value: this.totalData.procurementMoney || 0,
        },
        {
          name: "房租费用",
          value: this.totalData?.rentMoney?.totalAmounts || 0,
          tooltip: {
            已付总额: this.totalData?.rentMoney?.payments || 0,
            未付总额: this.totalData?.rentMoney?.nonPaymentAmounts || 0,
          },
        },
        {
          name: "物业费",
          value: this.totalData?.propertyFee?.totalAmounts || 0,
          tooltip: {
            已付总额: this.totalData?.propertyFee?.payments || 0,
            未付总额: this.totalData?.propertyFee?.nonPaymentAmounts || 0,
          },
        },
        {
          name: "水电费",
          value: this.totalData?.waterAndElectricity?.totalAmounts || 0,
          tooltip: {
            已付总额: this.totalData?.waterAndElectricity?.payments,
            未付总额: this.totalData?.waterAndElectricity?.nonPaymentAmounts,
          },
        },
        {
          name: "手续费",
          value: this.totalData.serviceCharge || 0,
        },
        {
          name: "公关费",
          value: this.totalData.publicRelationSpending || 0,
        },
      ];

      console.log(data[3]);
      let v =
        data[0].value ||
        0 + data[1].tooltip["已付总额"] ||
        0 + data[2].tooltip["已付总额"] ||
        0 + data[3].tooltip["已付总额"] ||
        0 + data[4].value ||
        0 + data[5].value ||
        0;

      if (!v) {
        v = 0;
      }
      data.unshift({
        name: "总支出",
        value: v,
      });
      return data;
    },
  },
  methods: {
    async loadData(data) {
      getMoneyMessage().then((result) => {
        // console.log(result);
        console.log(result.data);
        this.totalData = result.data;
      });

      // let res = await list({ ...data, type });

      // console.log(res)
      // this.totalData = res.data.totalAmount;

      // return {
      // list:lists,
      // total: res.data.basicPageVo.total,
      // };
      // console.log("加载数据");
    },
  },
  created() {
    window.$office = this;
    this.loadData();
  },
};
</script>
·


<template>
    <div class="contract-1">
        <custom-table ref="customTable" :loadData="loadData"  @rowClick="rowClick" :remove="remove" :drawers="drawers" :edit="edit" :pageInfo="pageInfo"
            :filters="filters" :tableColumns="tableColumns" />
        <childDetail v-if="showDetail" @editDetail="editDetail" :detailInfo="detailInfo"></childDetail>
    </div>
</template>

<script>
import * as moment from "moment"
import { list, add, remove, update,updateAffix,addAffix } from "@/api/office.js"
import { customerList } from "@/api/customer"
import childDetail from "./child-detail.vue"
const type = 1
export default {
    components:{
        childDetail
    },
    data() {
        return {
            type,
            showDetail:false,
            detailInfo:{},
            filters: [
                {
                    name: "项目名称",
                    value: "",
                    property: "projectName",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    clearable: true
                },
            ],
            drawers: [
                {
                    name: "项目名称",
                    value: "",
                    property: "projectName",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    require: true,
                    clearable: true
                }, {
                    name: "合同金额",
                    value: 0,
                    property: "contractMoneyAmount",
                    defaultValue() {
                        return ''
                    },
                    type: "number",
                    require: true,
                    clearable: true
                }, {
                    name: "服务周期",
                    property: "period",
                    value: null,
                    type: 'date-rand',
                    require: true,
                    defaultValue() {
                        return null
                    },
                    formatDrawer: (date) => {
                        if (date) {
                            return [new Date(date.split("~")[0]), new Date(date.split("~")[1])]
                        }
                        return null
                    },
                    formatCommit: (date) => {
                        return moment(new Date(date[0])).format("YYYY-MM-DD") + '~' + moment(new Date(date[1])).format("YYYY-MM-DD")
                    },

                    clearable: true
                }, {
                    name: "物业单位",
                    property: "unit",
                    value: null,
                    type: 'select',
                    require: true,
                    defaultValue() {
                        return null
                    },
                    clearable: true,
                    options: []
                }, {
                    name: "合同附件pdf",
                    value: '',
                    property: "url",
                    require: false,
                    defaultValue() {
                        return ''
                    },

                    type: 'file',
                    clearable: true,
                    fileTypes: ".pdf"
                },
            ],
            tableColumns: [
                {
                    name: "项目名称",
                    props: "projectName",
                },
                {
                    name: "总额",
                    props: "contractMoneyAmount",
                    isFormat: true,
                    format: (price) => {
                        return "￥" + price
                    }
                },
                // {
                //     name: "未付总额",
                //     props: "nonPaymentAmounts",
                //     isFormat: true,
                //     format: (price) => {
                //         return "￥" + price
                //     }
                // },
                // {
                //     name: "已付总额",
                //     props: "payments",
                //     isFormat: true,
                //     format: (price) => {
                //         return "￥" + price
                //     }
                // },
                {
                    name: "服务周期",
                    props: "period",
                    // isFormat: true,
                    // format: (date) => {
                    //     return moment(new Date(date)).format("YYYY-MM-DD")
                    // },
                    // formatCommit: (date) => {
                    //     return moment(new Date(date)).format("YYYY-MM-DD")
                    // }
                },
                {
                    name: "物业单位",
                    props: "unit",
                    // isFormat: true,
                    // format: (date) => {
                    //     return moment(new Date(date)).format("YYYY-MM-DD")
                    // },
                    // formatCommit: (date) => {
                    //     return moment(new Date(date)).format("YYYY-MM-DD")
                    // }
                },
                {
                    name: "合同附件pdf",
                    url: 'url',
                    isPdf: true
                },
                {
                    name: "操作",
                    isEdit: true,
                    edits: ['remove', 'edit']
                }
            ],
            pageInfo: {
                pageSize: 10,
                pageNumber: 1,
                total: 1000
            },
            totalData: 0
        }
    },
    computed: {
        statistical() {

            let data = [
                {
                    name: "固定资产总额",
                    value: this.totalData
                },
            ]
            return data
        },

    },
    methods: {
       async editDetail(info){
           for(let item of info.officeAffixVoList){
            if(item.invoiceDate){
            item.invoiceDate=moment(item.invoiceDate).format("YYYY-MM-DD")
            }
            if(item.payDate){
                item.payDate=moment(item.payDate).format("YYYY-MM-DD")
            }
            if(item.id){
                await updateAffix(item)
            }else{
                await addAffix({...item,officeId:this.detailInfo.id})
            }
           }    

           this.$refs['customTable'].loadDatas()
           $office.loadData()

           this.$message.success("编辑开票/收票信息成功！")
           this.showDetail=false
        },  
        rowClick(row){
            this.showDetail=true
            if(!row.officeAffixVoList){
                row.officeAffixVoList=[]
            }
            this.detailInfo=JSON.parse(JSON.stringify(row))
            
        },  
        async loadCustomList() {
            let data = await customerList({
                pageSize: 10000,
                pageNumber: 1,
                type: 1
            })

            let list = data.data.list
            list.map(e => {
                e.type = e.name
            })

            this.drawers[3].options = list


        },
        async loadData(data) {
          
            delete data['total']
           let lists = [];
      let total = 0;
      try {
        let res = await list({ ...data, type });
        lists = res.data.basicPageVo.list;
        total = res.data.basicPageVo.total;
      } catch (error) {}
      return {
        list: lists,
        total,
      };


        },
        async remove(row) {
            await remove({
                id: row.id,
                type
            })
            $office.loadData()


        },
        async edit(data) {
            // delete data['id']

            let httpMethod = data.drawerType == 'edit' ? update : add
            delete data['drawerType']
            await httpMethod({ office1Dto: { ...data, type } })
            $office.loadData()
            

        },

    },
    created() {
        this.loadCustomList()
    }

}
</script>
import axios from "@/lib/axios";

export const customerList = (params) => {
    return axios.request({
        url: "customers/getCustomer",
        params
    })
}


export const addCustomer=(data)=>{
    return axios.request({
        url:"customers/addCustomer",
        data,
        method:'post'
    })
}

export const deleteCustomer=({
    id
})=>{
    return axios.request({
        url:"customers/deleteCustomer",
        params:{
            id
        }
    })
}

export const updateCustomer=(data)=>{
    return axios.request({
        url:"customers/updateCustomer",
        data,
        method:"post"
    })
}
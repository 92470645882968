<style lang="scss" scoped>
.child-detail{
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    position: fixed;
    left:0;
    top:0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .main{
        width: 900px;
        background: white;
        overflow-y: auto;
        padding: 12px;
        border-radius: 4px;
        max-height: 100%;
        overflow-y: auto;
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-bottom: 12px;
            margin-bottom: 12px;
            border-bottom: 1px solid $grow-min;
            .el-icon-close{
                cursor: pointer;
                font-size: 24px;
            // border-bottom: 1px solid $grow-min;
            }
        }
        .footer{
            display: flex;
            padding-top: 12px;
            border-top: 1px solid $grow-min;
            justify-content: right;
            margin-top: 12px;
        }
    }
    .list{
        margin-bottom: 12px;
        .list-item{
            width: 100%;
            display: flex;
            margin-bottom: 12px;
            .row{
                flex-grow: 1;
                font-size: 14px;
                border-bottom: 1px solid $grow-min;
                padding-bottom: 12px;
                padding-top: 12px;
            }
        }
    }
}
</style>

<template>
    <div class="child-detail">
        <div class="main">
            <div class="header">
                <div>开票/收票详情</div>
                <div class="el-icon-close" @click="close"></div>
            </div>
            <div class="content">
                <div class="list">
                    <!-- {{ detailInfo.officeAffixVoList }} -->
                    <div class="list-item">
                        <div class="row">付款金额</div>
                        <div class="row">付款日期</div>
                        <div class="row">发票日期</div>
                        <div class="row">发票金额</div>
                    </div>
                    <div class="list-item" v-for="item of detailInfo.officeAffixVoList ">
                        <div class="row">
                            <el-input-number style="width:200px" v-model="item.payAmount"></el-input-number>
                        </div>
                        <div class="row">
                          <el-date-picker  style="width:200px" v-model="item.payDate"></el-date-picker>

                        </div>
                        <div class="row">
                            <el-input-number style="width:200px" v-model="item.invoiceAmount"></el-input-number>

                        </div>
                        <div class="row">
                            <el-date-picker style="width:200px" v-model="item.invoiceDate"></el-date-picker>

                        </div>
                    </div>
                </div>
                <div class="create-button">
                    <el-button type="primary" style="width:100%" @click="createNewRow">创建收支记录</el-button>
                </div>
            </div>
            <div class="footer">
                <el-button type="primary" @click="commit">确定</el-button>
                <el-button @click="close">取消</el-button>
            </div>
        </div>
    </div>
</template>


<script>
const defaultRow=()=>{
    return{
        payAmount:0,
        payDate:null,
        invoiceAmount:0,
        invoiceDate:null,

    }
}
export default{
    props:{
        detailInfo:Object
    },
    methods:{
        createNewRow(){
            this.detailInfo.officeAffixVoList.push(defaultRow())
        },
        close(){
            this.$parent.showDetail=false
        },
        commit(){
            this.$emit("editDetail",this.detailInfo)
        }
    }
}

</script>

import axios from "@/lib/axios";

export const list = (params) => {
    return axios.request({
        url: "fix/getFix",
        params
    })
}

export const add=(data)=>{
    return axios.request({
        url:"fix/addFix",
        data,
        method:'post'
    })
}

export const remove=({
    id
})=>{
    return axios.request({
        url:"fix/deleteFix",
        params:{
            id
        }
    })
}

export const update=(data)=>{
    return axios.request({
        url:"fix/updateFix",
        data,
        method:"post"
    })
}

export const getTotalMoney=()=>{
    return axios.request({
        url:"taxLabor/getTotalMoney"
    })
}

